<template>
  <div>
    <div style="width: 100%;text-align: center;height: 50px;line-height: 50px;font-weight: bolder;font-size: 25px">
      {{ '中铁十二局川藏铁路项目公司周工作计划(' }} {{ weekstart }} {{ '--' }} {{ weekend }}{{ ')' }}
    </div>
    <div style="display: flex">
      <div style="width: 100%;">
        <div>
          <vxe-table
              border
              :align="allAlign"
              :data="thisweek"
              :span-method="mergeRowMethod"
              :cell-style="cellStyle"
              ref="xTable1"
              :scroll-y="{enabled: false}"
          >
            <vxe-column field="addpepart" width="120" title="科室"></vxe-column>
            <vxe-column field="message" width="550" title="工作目标及内容">
              <template #default="{ row }">
                <div style="float: left" @dblclick="changemes(row)">{{ row.message }}</div>
              </template>
            </vxe-column>
            <vxe-column field="mainpeo" width="90" title="负责人"></vxe-column>
            <vxe-column field="phdepart" width="120" title="配合部门(人员)"></vxe-column>
            <vxe-column field="timend" width="157" title="时间节点"></vxe-column>

            <vxe-column field="lastweekplan" width="530" title="上周工作完成情况(未完成标注红色)">
              <template #default="{ row }">
                <div style="float: left">{{ row.lastweekplan }}</div>
              </template>
            </vxe-column>
            <vxe-column field="beizhu" width="160" title="备注"></vxe-column>
          </vxe-table>
        </div>
      </div>
    </div>
    <!--    修改工作计划-->
    <div>
      <el-dialog
          v-model="dialogVisible1"
          title="修改周工作计划"
          width="80%"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
      >
        <div style="display: flex;margin-bottom: 20px">
          <el-tag type="info" style="margin-right: 10px">工作目标及内容</el-tag>
          <el-input v-model="workmes" placeholder="工作目标及内容" size="small"/>
        </div>
        <div style="display: flex;margin-bottom: 20px">
          <el-tag type="info" style="margin-right: 10px">&#12288;负&#12288;责&#12288;人&#12288;</el-tag>
          <el-input v-model="mainpeosel" placeholder="请输入负责人" size="small" style="width: 200px;margin-left: 20px"/>
        </div>
        <div style="display: flex;margin-bottom: 20px">
          <el-tag type="info" style="margin-right: 10px">配合部门(人员)</el-tag>
          <el-input v-model="phpeoedit" placeholder="请输入配合部门(人员)" size="small" style="width: 200px;margin-left: 20px"/>
        </div>
        <div style="display: flex;margin-bottom: 20px">
          <el-tag type="info" style="margin-right: 10px">时&#12288;间&#12288;节&#12288;点</el-tag>
          <el-date-picker
              style="width: 200px"
              size="small"
              v-model="endtime"
              type="date"
              placeholder="时间节点"
              format="YYYY/MM/DD"
              value-format="YYYY-MM-DD"
          >
          </el-date-picker>
        </div>
        <div style="display: flex;margin-bottom: 20px">
          <el-tag type="info" style="margin-right: 10px">&#12288;&#12288;备&#12288;注&#12288;&#12288;</el-tag>
          <el-input v-model="beizhu" placeholder="请输入备注" size="small"/>
        </div>
        <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose1">取消</el-button>
        <el-button type="primary" @click="editworkplan">提交</el-button>
      </span>
        </template>
      </el-dialog>
    </div>
    <div style="float: right;right: 30px">
      <el-button type="warning" plain @click="audit">审核完成</el-button>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import {ElMessage} from "element-plus";

export default {
  name: "planaudit",
  data(){
    return{
      weeknum: '',
      weekstart: '',
      weekend: '',
      nextweeknum: '',
      allAlign: 'center',
      thisweek: [],
      lastweek: [],
      dialogVisible1: false,
      weekmes: '',
      endtime: '',
      phpeoedit: '',
      mainpeos: [],
      mainpeosel: '',
      editid: '',
      workmes: '',
      beizhu: '',
      windowHeight: document.documentElement.clientHeight
    }
  },
  created() {
    console.log(this.windowHeight)
    let re2 = /[\u4e00-\u9fa5]{2,}/g
    this.week = this.$route.params.weeknum;
    this.weekstart = this.$route.params.weekstart;
    this.weekend = this.$route.params.weekend;
    this.weeknum = dayjs(this.week).week();
    this.getallplan();
  },
  methods: {
    getallplan(){
      this.axios.get("/weekplan/getall", (response) => {
        // let last = [];
        // let thisweek = [];
        // for (let i = 0; i < response.obj.length; i++){
        //   if (i % 2 === 0){
        //     for (let y = 0; y < response.obj[i].length; y++){
        //       last.push(response.obj[i][y]);
        //     }
        //   }else {
        //     for (let y = 0; y < response.obj[i].length; y++){
        //       thisweek.push(response.obj[i][y]);
        //     }
        //   }
        // }
        // this.lastweek = last;
        // this.thisweek = thisweek;
        console.log(response.obj)
        this.thisweek = response.obj
      },{
        weeknum: this.weeknum
      })
    },
    mergeRowMethod({row, _rowIndex, column, visibleData}) {
      const fields = ['addpepart']
      const cellValue = row[column.property]
      if (cellValue && fields.includes(column.property)) {
        const prevRow = visibleData[_rowIndex - 1]
        let nextRow = visibleData[_rowIndex + 1]
        if (prevRow && prevRow[column.property] === cellValue) {
          return {rowspan: 0, colspan: 0}
        } else {
          let countRowspan = 1
          while (nextRow && nextRow[column.property] === cellValue) {
            nextRow = visibleData[++countRowspan + _rowIndex]
          }
          if (countRowspan > 1) {
            return {rowspan: countRowspan, colspan: 1}
          }
        }
      }
    },
    cellStyle ({ row, rowIndex, column }) {
      if (column.property === 'lastweekplan') {
        if (row.lastaudit === 0) {
          return {
            backgroundColor: 'red',
            color: '#ffffff'
          }
        }
      }
      if (column.property === 'lastweekplan') {
        if (row.lastaudit === 1) {
          return {
            backgroundColor: 'green',
            color: '#ffffff'
          }
        }
      }
    },
    changemes(row){
      console.log(row)
      this.editid = row.id;
      console.log(row.id)
      this.dialogVisible1 = true;
      this.axios.post("/userinfo/getleader", (response) => {
        this.mainpeos = response.obj
      }, {
        depart: this.GET_DEPART
      });
      this.dialogVisible1 = true;
      this.axios.get("/weekplan/getplanForEdit", (response) => {
        console.log(response.obj)
        this.workmes = response.obj.message;
        this.mainpeosel = response.obj.mainpeo;
        this.phpeoedit = response.obj.phdepart;
        this.endtime = response.obj.timend;
        this.beizhu = response.obj.beizhu
      },{
        id: row.id
      })
    },
    handleClose1() {
      this.dialogVisible1 = false;
      this.workmes = '';
      this.mainpeosel = '';
      this.phpeoedit = '';
      this.endtime = '';
    },
    editworkplan(){
      this.axios.post("/weekplan/editplan", (response) => {
        if (response.obj){
          this.dialogVisible1 = false;
          this.getallplan();
          ElMessage({
            message: '修改成功',
            type: 'success',
          });
        }
      },{
        message: this.workmes,
        phdepart: this.phpeoedit,
        mainpeo: this.mainpeosel,
        endtime: this.endtime,
        id: this.editid
      })
    },
    audit(){
      this.axios.get("/weekplan/audit", (response) => {
        if (response.obj){
          ElMessage({
            message: '审核成功',
            type: 'success',
          });
        }
      },{
        weeknum: this.weeknum
      })
    }
  }
}
</script>

<style scoped>

</style>